import React from 'react';
import Tag from 'components/tag';
import PropTypes from 'prop-types';
import { TcIconLogo } from 'components/IconSystem';
import { get_api_products_info as getApiProductsInfo } from 'components/api';
import { href_inform as hrefInform } from 'components/href-helper';
import { isPc as isPC } from 'components/env';
import { getProductId } from 'components/util';
import { TXC_INFORM_ID  } from 'components/constants/app';
import './style.less';
class PoweredBy extends React.Component {
  constructor(props) {
    super(props);
    this.informProductId = TXC_INFORM_ID; // 线上环境 333559  测试环境 264646
    this.productId = getProductId();
    this.state = {
      // isTencentProduct: null,
    };
  }
  componentDidMount() {
    // this.getProductInfo();
  }

  getProductInfo() {
    if (!isPC) {
      return;
    }
    // getApiProductsInfo(this.productId)
    //   .then((res) => {
    //     this.setState({
    //       isTencentProduct: !res.data.is_tencent_product,
    //     });
    //   });
  }

  render() {
    // const { isTencentProduct } = this.state;
    return (
      <>
        <TagPoweredBy
          isPC={isPC}
          productId={this.productId}
          informProductId={this.informProductId}
          // isTencentProduct={isTencentProduct}
        />
      </>
    );
  }
}

const TagPoweredBy = props => (
  <Tag.div className="power_by">
    <TcIconLogo className="tcIcon_logo" />
    <Tag.a href={`https://txc.qq.com?from=${props.isPC ? 'powerbypc' : 'powerbymobile'}`} className="power_by-link" target="_blank">腾讯兔小巢技术支持</Tag.a>
    {props.isPC && (
      <Tag.div className="power_by__inform">
        <Tag.span className="power_by__inform-symbol">·</Tag.span>
        <Tag.a href={hrefInform(props.informProductId, props.productId)} className="power_by-link" target="_blank">产品举报</Tag.a>
      </Tag.div>
    )}
  </Tag.div>
);

TagPoweredBy.propTypes = {
  isPC: PropTypes.bool,
  isTencentProduct: PropTypes.bool,
  informProductId: PropTypes.number,
  productId: PropTypes.string,
};

export { PoweredBy };
