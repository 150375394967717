import React from 'react';
import { TcIconCoding } from 'components/IconSystem';

export const BtnPlatforms = React.forwardRef((props, ref) => (
    <div className={`btn btn-platforms ${props.className}`} onClick={props.onClick} ref={ref}>
        {props.name.toLowerCase() === 'coding' && <TcIconCoding />}
        <span className="btn-text">{props.name}</span>
    </div>
));

BtnPlatforms.defaultProps = {
    onClick: () => console.log('onClick 未绑定'),
};
