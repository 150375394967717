import  * as configs from '../../app.config';
const pageList = {
  ...configs,
};
Object.keys(pageList).forEach((key) => {
  const path = pageList[key];
  // 页面跳转是需要是绝对路径
  if (typeof path === 'string') {
    pageList[key] = `/${path}`;
  }
});
export default pageList;
