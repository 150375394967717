import React, { Fragment } from 'react';
import classNames from 'classnames';
import Tag from 'components/tag';
import RichText from 'components/rich-text';
import { href_profile as hrefProfile } from 'components/href-helper';
import { ImageMobile } from 'components/comment-image';
import { dangerouslySetInnerHTML } from 'components/render-rich-text';

import {  RepliesPropTypes } from './prop-types.js';

export const CommentItem = props => (
  <Tag.div className="comment_list_item">
    <Tag.div className="comment_font" >
      <Tag.span
        isText
        className="user_name"
      >
        <Tag.a
          isText
          className={classNames('link', { admin: props.is_admin })}
          href={hrefProfile(props.user_id)}
          aria-label={`${props.is_admin ? '管理员' : ''}${props.nick_name} 前往${props.nick_name} 个人中心`}
        >
          {props.nick_name}{(!props.parent || !props.parent_reply_id) && '：'}
        </Tag.a>
        {props.parent && props.parent_reply_id && (
          <Fragment>
            <Tag.i isText className="jt" role="img" aria-label="回复" />
            <Tag.a
              isText
              className={classNames('link', { admin: props.parent.is_admin })}
              href={hrefProfile(props.parent.user_id)}
              aria-label={`${props.parent.is_admin ? '管理员' : ''}${props.parent.nick_name} 前往${props.parent.nick_name} 个人中心`}
            >{props.parent.nick_name}：</Tag.a>
          </Fragment>
        )}
      </Tag.span>
      <RichText isText className="desc_font" content={dangerouslySetInnerHTML(props.f_reply_text, '', 'br', props.renderLink === 'on' || props.is_admin).__html} />
    </Tag.div>
    {
      (Boolean(props.images.length)) && (
        <Tag.div className="comment_pic">
          <ImageMobile imgList={props.images} />
        </Tag.div>
      )
    }
  </Tag.div>
);

CommentItem.defaultProps = {
  is_admin: false,
  nick_name: '',
  f_reply_text: '',
  parent_reply_id: null,
  images: [],
  renderLink: 'on',
};

CommentItem.propTypes = RepliesPropTypes;
