import PropTypes from 'prop-types';

/* ID 的类型，有可能是字符串或者数组 */
const IDPropTypes = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

const ImagesPropTypes = PropTypes.shape({
  thumb_url: PropTypes.string.isRequired,
  large_url: PropTypes.string.isRequired,
  original_url: PropTypes.string.isRequired,
});

/* 帖子外露评论的 JSON 结构 */
export const RepliesPropTypes = PropTypes.shape({
  /* 评论ID */
  f_reply_id: IDPropTypes,
  /* 评论正文 */
  f_reply_text: PropTypes.string.isRequired,
  /* 创建时间 */
  created_at: PropTypes.string.isRequired,
  /* 评论用户的ID */
  user_id: IDPropTypes,
  /* 评论用户的昵称 */
  nick_name: PropTypes.string.isRequired,
  /* 是否管理员 */
  is_admin: PropTypes.bool,
  /* 评论的上一级 */
  parent_reply_id: IDPropTypes,
  /* 是否允许非管理员回复链接 */
  renderLink: PropTypes.string,
});

/* 帖子的 JSON 结构约定 */
export const PostPropTypes = {
  /* 列表ID */
  list_id: IDPropTypes,
  /* 帖子ID */
  id: IDPropTypes,
  /* 发帖用户ID */
  user_id: IDPropTypes,
  /* 发帖用户昵称 */
  nick_name: PropTypes.string.isRequired,
  /* 发帖用户头像 */
  avatar_url: PropTypes.string,
  /* 帖子正文内容 */
  content: PropTypes.string.isRequired,
  /* 创建时间 */
  created_at: PropTypes.string.isRequired,
  /* 格式化后的创建时间 */
  time: PropTypes.string.isRequired,
  /* 正文的图片 */
  images: PropTypes.arrayOf(ImagesPropTypes),

  /* 置顶标示 */
  is_top: PropTypes.bool,
  /* 锁帖标示 */
  is_locked: PropTypes.bool,
  /* 精华标示 */
  is_good: PropTypes.bool,
  /* 隐藏标示，仅管理员可见 */
  is_hidden: PropTypes.bool,
  /* 加黑标示，仅管理员可见 */
  is_spam: PropTypes.bool,
  /* 代办标示，仅管理员可见 */
  is_todo: PropTypes.bool,
  /* 举报标示，仅管理员可见 */
  is_abuse: PropTypes.bool,

  /* 点赞量 */
  like_count: PropTypes.number,
  /* 评论量 */
  reply_num: PropTypes.number,
  /* 帖子外露的部分评论 */
  replies: PropTypes.objectOf(RepliesPropTypes),
  /* 是否是话题 */
  topic: PropTypes.bool,
  /* 是否允许管理员回复链接 */
  renderLink: PropTypes.string,
};
