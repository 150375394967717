import { useRef, useEffect } from 'react';

/**
 * 小程序逻辑不一样
 */
interface UseShowOnPageProps {
  /** 元素出现后的事件回调 */
  onShow: () => void;
  /** 目标元素的ID。小程序需要， */
  id: string;
  /** 由于是绑定ref的 需要使用trigger 来强制刷新 */
  trigger: any;
}
const useShowOnPage = (props: UseShowOnPageProps) => {
  const showElemRef = useRef<Element | null>(null);
  const Observer = useRef<any>();

  const handleCallback: IntersectionObserverCallback = (entries) => {
    if (!entries[0].isIntersecting) return;
    props.onShow && props.onShow();
  };


  useEffect(() => {
    const element = showElemRef.current;
    if (!element || Observer.current) return;
    Observer.current = ObserverCreater(handleCallback);
    Observer.current.observe(element);
    return () => {
      Observer.current.unobserve(element);
    };

    // 这里通过监听props的变化来使useState的值变化
  }, [props.trigger]);

  return { showElemRef };
};


const ObserverCreater = (onShow: IntersectionObserverCallback) => {
  // 需要用 window.IntersectionObserver 而不能直接 IntersectionObserver，否则 iOS9 会报错
  // ReferenceError: Can't find variable: IntersectionObserver
  if (!window.IntersectionObserver) {
    console.log('LoadMoreHook 依赖 IntersectionObserver API，当前 runtime 不包含该 API，请检查 polyfill');

    return {
      observe() { },
      unobserve() { },
    };
  }

  return new IntersectionObserver(onShow);
};


export default useShowOnPage;
