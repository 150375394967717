import React, { FC, useEffect, useState } from 'react';
import Tag from 'components/tag';
import { AD_INDEXES } from 'components/api/wjapi';
import { useAd } from 'components/ad-resources/hooks/useAd';
import { getProductId } from 'components/util';
import PAGE from 'components/constants/page';
import Route from 'components/util/route';
import { Collect } from 'components/report';
import { Avatar } from 'components/user/avatar';
import useShowOnPage from 'components/hooks/useShowOnPage';


import './style.less';
import useNotice from './useNotice';

enum AdType {
  /** 首屏banner广告 */
  Banner = 'banner',
  /** 浮动广告 */
  Float = 'float',
  /** 信息流广告1 */
  Feed = 'feed',
  /** 信息流广告2 */
  Feed2 = 'feed2',
  /** 常见问题底部广告 */
  Faq = 'faq'
}

const AdTypeMap = {
  [AD_INDEXES.HOME]: AdType.Feed,
  [AD_INDEXES.HOME2]: AdType.Feed2,
  [AD_INDEXES.HOME3]: AdType.Banner,
  [AD_INDEXES.HOME4]: AdType.Float,
  [AD_INDEXES.FAQ]: AdType.Faq,
};
interface PostAdProps {
  indexes: string
}

enum Status {
  Normal,
  Closing,
  Closed
}
const rootCls = 'txc-recommend';

const getEcByIndexes = indexes => (indexes !== AD_INDEXES.FAQ ? '产品社区首页-移动端' : 'FAQ详情页-移动端');
const getTypeByIndexes = indexes => AdTypeMap[indexes];


const PostAd: FC<PostAdProps> = (props) => {
  const type = getTypeByIndexes(props.indexes);
  const [showClose, setShowClose] = useState(false);
  const [status, setStatus] = useState(Status.Normal);
  const { isLoading, data } = useAd({ productId: getProductId(), indexes: props.indexes });
  const { notice } = useNotice({ trigger: (type === AdType.Float) && (status !== Status.Closed) });
  const onShow = () => {
    new Collect({
      ec: getEcByIndexes(props.indexes),
      ea: 'EXPOSURE',
      el: data?.description,
    }).report();
  };

  const { showElemRef } = useShowOnPage({ onShow, id: rootCls, trigger: `${isLoading}_${status}` });


  if (isLoading || !data || status === Status.Closed) return null;


  const handleButtonClick = (e) => {
    new Collect({
      ec: getEcByIndexes(props.indexes),
      ea: 'CLICK',
      el: '广告按钮',
      ev: data.id as any,
    }).report();
    setShowClose(showClose => !showClose);
  };

  const handleClose = () => {
    new Collect({
      ec: getEcByIndexes(props.indexes),
      ea: 'CLICK',
      el: '广告按钮-关闭广告',
      ev: data.id as any,
    }).report();
    setStatus(Status.Closed);
  };

  const handleBannerClick = () => {
    new Collect({
      ec: getEcByIndexes(props.indexes),
      ea: 'CLICK',
      el: data?.description,
    }).report();
    if (process.env.TARO_ENV === 'weapp') {
      Route.push({
        url: `${PAGE.PAGE_WEB_VIEW}?url=${encodeURIComponent(data.link)}`,
      } as any);
    } else {
      Route.push(data.link);
    }
  };

  const hasAvatar = type === AdType.Feed2;
  const noticeContent = type === AdType.Float ? notice : '本活动由第三方提供，具体活动规则请见跳转后的页面';

  return (
    // 这里不用ad相关单词做类名。容易被chrome上一些广告拦截插件给干掉
    <Tag.div
      ref={showElemRef}
      id={rootCls}
      className={`${rootCls} ${rootCls}--${type}`}
    >
      {hasAvatar && (
        <Tag.div className="txc-recommend__avatar-wrap" onClick={handleBannerClick}>
          <Avatar src={data.small_image_url} className="txc-recommend__avatar" />
        </Tag.div>
      )}
      <Tag.div className="txc-recommend__main-wrap">
        <Tag.div className="txc-recommend__banner-wrap" onClick={handleBannerClick}>
          <Tag.div
            className="txc-recommend__banner"
            style={{ backgroundImage: `url(${data.image_url})` }}
          >
            <Tag.div className="txc-recommend__ctrl" onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}>
              <Tag.div className="txc-recommend__button" onClick={handleButtonClick}>
                <Tag.span isText>广告</Tag.span>
                <Tag.div className="txc-recommend__button-icon" />
              </Tag.div>
              {showClose && (<Tag.button className="txc-recommend__close" onClick={handleClose}>关闭</Tag.button>)}
            </Tag.div>
          </Tag.div>
        </Tag.div>
        <Tag.p className="txc-recommend__notice">
          <Tag.p className="txc-recommend__notice-wrap">{noticeContent}</Tag.p>
        </Tag.p>
      </Tag.div>
    </Tag.div>
  );
};

export default PostAd;
