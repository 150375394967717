/* eslint-disable camelcase, react/prop-types */
import React, { Component } from 'react';
import { sortBy, last, isEmpty, flatten, pick, omit, difference, chunk, extend } from 'lodash-es';
import PropTypes from 'prop-types';

import {
  get_api_posts_replies,
  get_api_v2_posts_user,
  get_api_v2_posts_static,
  get_api_v2_posts_wilson,
  get_api_v2_posts_label_show,
  get_api_v2_posts_label_hidden,
  get_api_v2_posts_label_good,
  post_api_post_reply_liked,
  post_api_posts_replies_liked,
  post_api_posts_liked,
  post_api_abuse_reports,
  post_api_tip_batch_list,
  post_api_tip_batch_info,
  post_api_v2_posts_liked,
  post_api_v2_posts,
  post_api_v2_posts_replies,
  delete_api_posts,
  delete_api_posts_replies,
  getProductsTagsList,
  postListTagsAllGet,
  getProductIDTopicsList,
  getApiTopicsPostsPopular,
} from 'components/api';
import { toHashObj, getValueFromHash } from 'components/util';
import { PostListLabel, LABELS } from 'components/post-label';
import { ENTERPRISE_VERSION } from 'components/constants/enterprise';
import { fromEntries } from 'components/util/new-attribute';

/**
 * 帖子列表解决方案
 * 1.获取标签
 * 2.根据标签，加载不同的列表数据
 * 3.对列表数据进行合并去重
 * 4.对列表数据进行排序
 * 5.输出排序后的列表数组供子组件使用
 * @features
 * - [x] 默认标签，灰度威尔逊算法
 * - [x] 增加最新标签
 * - [x] 管理员状态下，增加隐藏标签
 * - [x] 合并 Mobile 端和 PC 端的 render props 处理
 * - [x] 删除帖子、评论，需要更新
 * - [x] 发布评论、回复、发帖等需要更新列表
 * - [x] 置顶、好问题、禁止回复、加黑等管理员操作需要更新列表
 * - [x] 举报帖子
 * - [x] 打赏信息
 */
export class PostListSolution extends Component {
  /**
     * 获取用户的点赞信息
     * @param postIds
     * @param replyIds
     * @param productId
     */
  static asyncGetLiked({ postIds = [], replyIds = [], productId }) {
    /**
         * @param productId
         * @param ids
         * @param type
         * @return {Promise<unknown>}
         */
    const getData = (productId, ids, type) => new Promise((resolve) => {
      if (ids.length === 0) {
        return resolve();
      }

      // 接口1次只能处理200个id，如果大于200，分开请求
      if (ids.length > 200) {
        const chunkIds = chunk(ids, 200);
        const totals = chunkIds.map(maxIds => getData(productId, maxIds, type));
        Promise.all(totals).then(resp => resolve(flatten(resp)));
        return;
      }

      if (type === 'post') {
        post_api_v2_posts_liked({ productId, ids }).then(resp => resolve(resp.data));
      }

      if (type === 'reply') {
        post_api_post_reply_liked({ productId, ids }).then(resp => resolve(resp.data));
      }
    });

    return new Promise((resolve) => {
      Promise.all([
        getData(productId, postIds, 'post'),
        getData(productId, replyIds, 'reply'),
      ]).then(resolve);
    });
  }

  /**
     * 合并用户点赞行为到帖子中
     * @param item              帖子信息
     * @param postLikedState    帖子点赞
     */
  static mergeLiked(item, postLikedState) {
    const postId = item.id;

    if (postLikedState.post[postId]) {
      item.is_liked = postLikedState.post[postId].like_flag;
      item.like_count = postLikedState.post[postId].like_count;
    }

    // item.replies 有可能为 undefined
    if (typeof item.replies !== 'undefined') {
      const replyIds = Object.keys(item.replies);
      item.repliesLiked = pick(postLikedState.reply, replyIds);
    }

    return item;
  }

  /**
     * 标签数据聚合更新
     * @param item              帖子信息
     * @param postTagState    帖子点赞
     */
  static mergeTag(item, postTagState) {
    const postId = item.id;

    if (postTagState.post[postId]) {
      item.is_liked = postTagState.post[postId].like_flag;
      item.like_count = postTagState.post[postId].like_count;
    }

    // item.replies 有可能为 undefined
    if (typeof item.replies !== 'undefined') {
      const replyIds = Object.keys(item.replies);
      item.repliesLiked = pick(postTagState.reply, replyIds);
    }

    return item;
  }

  /**
     * 计算点赞数
     * @param {number} preCount  之前的点赞数
     * @param {boolean} preFlag   之前的点赞状态
     */
  static calcLikeCount(preCount, preFlag) {
    if (preCount === 0) {
      return preFlag ? 0 : 1;
    }
    return preFlag ? preCount - 1 : preCount + 1;
  }

  /**
   * 计算最新的点赞人数列表。 前端管理点赞状态
   */
  static calcLikeList(preLikeList, preFlag, user) {
    if (!user || !user.nickname) {
      return preLikeList;
    }
    return preFlag ? preLikeList.filter(item => item !== user.nickname)
      : [user.nickname].concat(preLikeList);
  }

  /**
     * 帖子排序
     * @param {object} noticeList    置顶的帖子
     * @param {object} postsList     正常的帖子列表
     * @param {object} userList      用户态的帖子，管理员看到的全都帖子、非管理员看到的个人帖子
     * @param {string} label         当前标签
     * @param {string} [type]        排序类别
     * @param {string} [topic]       话题排序
     * @return {array} result.noticeList
     * @return {array} result.postsList
     */
  static sortList({ noticeList, postsList, userList, label }, type, topic) {
    const noticeKeys = Object.keys(noticeList);

    let resultPostList = postsList;

    // 默认标签，合并帖子数据和用户数据
    if ([LABELS.DEFAULT].includes(label)) {
      resultPostList = { ...resultPostList, ...userList };
    }

    // 剔掉重复数据
    resultPostList = omit(resultPostList, noticeKeys);

    switch (type) {
    case 'wilson':
      resultPostList = sortBy(resultPostList, item => Number(item.wilson_score)).reverse();
      break;
    default:
      // 按时间排序
      resultPostList = topic
        ? sortBy(resultPostList)
        : sortBy(resultPostList, item => Number(item.created_at_timestamp * 1000)).reverse();
    }

    let resultNoticeList = noticeKeys.map(key => noticeList[key]);

    // 好问题、隐藏标签等做一次二次过滤，方便管理员操作后实时更新
    const filterPostByLabel = (post) => {
      switch (label) {
      case LABELS.GOOD:
        return post.is_good;
      case LABELS.HIDDEN:
        return post.is_hidden;
      default:
        return !post.is_hidden;
      }
    };

    resultNoticeList = resultNoticeList.filter(filterPostByLabel);
    resultPostList = resultPostList.filter(filterPostByLabel);

    return {
      noticeList: resultNoticeList,
      postsList: resultPostList,
    };
  }

  /**
     * 拉取标签数据
     * @param currentLabel
     * @return {Function}
     */
  static getListByLabel(currentLabel) {
    if (currentLabel === LABELS.DEFAULT) {
      return get_api_v2_posts_wilson;
    }
    if (currentLabel === LABELS.GOOD) {
      return get_api_v2_posts_label_good;
    }

    if (currentLabel === LABELS.SHOW) {
      return get_api_v2_posts_label_show;
    }

    if (currentLabel === LABELS.HIDDEN) {
      return get_api_v2_posts_label_hidden;
    }

    if (currentLabel === LABELS.NEWEST) {
      return getProductIDTopicsList;
    }

    if (currentLabel === LABELS.POPULAR) {
      return getApiTopicsPostsPopular;
    }
    return () => Promise.reject(new Error('初始化产品标签'));
  }

  /**
     * 返回新旧两次的 ID 差集, 给增量的点赞和赞赏数据使用
     * @param prevState
     * @param currentState
     * @returns {Array}
     */
  static getDiffIds(prevState, currentState) {
    const existPosts = { ...prevState.noticeList, ...prevState.postsList };
    const existPostIds = Object.keys(existPosts);

    const allPosts = { ...currentState.noticeList, ...currentState.postsList };
    const postIds = Object.keys(allPosts);

    return difference(postIds, existPostIds);
  }

  /**
     * 获取用户最近的一条post数据
     * @param {object} userList
     */
  static getLatestUserPosts(userList = {}) {
    let latest;
    let curr;
    Object.keys(userList).forEach((postId) => {
      curr = userList[postId];
      if (!latest) {
        latest = curr;
        return;
      }

      if (curr.created_at_timestamp > latest.created_at_timestamp) {
        latest = curr;
      }
    });

    return latest ? { [latest.id]: latest } : {};
  }

  constructor(props) {
    super(props);

    this.productId = props.productId;


    this.state = {
      label: props.topic ? LABELS.POPULAR : LABELS.INIT,  // 标签

      isLoading: true,

      topicList: {},      // 话题帖子
      pagination: {},
      noticeList: {},     // 置顶帖子
      postsList: {},      // 普通帖子
      userList: {},       // 用户态的帖子，管理员能看全部，非管理员只能看自身

      post_liked: {},     // 帖子点赞
      reply_liked: {},    // 评论点赞

      rewardInfo: {},     // 是否允许打赏
      tipBatchList: {},    // 打赏数据

      tagArrList: [], // 全部标签列表
    };

    this.isWilsonBeta = true;
  }

  componentDidMount() {
    this._updateListByLabel();
  }

  componentDidUpdate(prevProps, prevState) {
    /**
         * 需要使用productsInfo作为前置条件
         * 来判断是否为本地产品
         */
    if (prevProps.productInfo && !prevProps.productInfo.id) {
      return;
    }

    // 只有腾讯对内产品默认展示最新排序
    if (prevState.label === LABELS.INIT) {
      this.initialLabel(prevProps.productInfo
        && prevProps.productInfo.is_tencent_product
        && prevProps.productInfo.access_type === ENTERPRISE_VERSION.PRIVATE);
    }

    /**
         * 当标签发生变化的时候，更新列表数据
         */
    if (prevState.label !== this.state.label) {
      this._updateListByLabel();
    }

    /**
         * 当用户切换登录态的时候，更新用户数据
         */
    if (prevProps.userData !== this.props.userData) {
      // 不单独获取自己发的帖子数据
      // this.asyncGetUserData();
    }

    /**
     * 当发布了新帖子的时候，获取用户的新帖子并置顶(所有标签状态下)
     */
    if (prevProps.submitPostSuccess !== this.props.submitPostSuccess) {
      if (this.props.submitPostSuccess > 0) {
        this.unshiftUserPost();
      }
    }

    /**
     * 帖子列表发生变化的时候，获取用户的点赞信息
     */
    if (prevState.postsList !== this.state.postsList
            || prevState.noticeList !== this.state.noticeList) {
      this._updateLikedByList(prevState);
    }

    /**
         * 帖子列表发生变化的时候，获取用户的打赏信息
         */
    if (prevState.postsList !== this.state.postsList
            || prevState.noticeList !== this.state.noticeList) {
      this._updateBatchByList(prevState);
    }
  }

  // 初始化当前标签
  initialLabel(isTencentProduct) {
    const urlLabel = getValueFromHash('label');
    let label = LABELS.DEFAULT;

    if (isTencentProduct) {
      label = LABELS.SHOW;
    }

    if (urlLabel) {
      label = urlLabel;
    }

    this.setState({ label });
  }

  /**
     * 对帖子标签做操作
     * @param postId
     * @param isTop
     * @param tag 更新后的标签列表
     * @returns {Promise}
     */
  asyncTagsList({ postId, isTop, tag }) {
    const { noticeList, postsList } = this.state;
    const list = isTop ? noticeList : postsList;
    for (const item of Object.values(list)) {
      if (item.id === postId) item.tags = tag;
    }
    isTop ? this.setState({ noticeList: list }) : this.setState({ postsList: list });
  }

  /**
     * 删除帖子或评论
     * @param postId        帖子ID
     * @param [replyId]     评论ID，如果存在，则是删除评论
     * @returns {Promise}
     */
  asyncDeletePostOrReply(postId, replyId) {
    const { productId, state } = this;

    if (replyId) {
      // 删除评论
      const deleteTargetReply = () => {
        // 三个列表中的帖子评论
        ['noticeList', 'postsList', 'userList']
          .filter(key => state[key][postId])
          .forEach((key) => {
            const targetPost = state[key][postId];

            // 删除评论
            targetPost.replies = omit(targetPost.replies, replyId);
            targetPost.reply_count -= 1;

            this.setState(prevState => ({
              [key]: {
                ...prevState[key],
                [postId]: targetPost,
              },
            }));
          });
      };

      return new Promise((resolve, reject) => {
        delete_api_posts_replies({
          productId,
          postId,
          replyId,
        }).then(deleteTargetReply)
          .then(resolve)
          .catch(reject);
      });
    }

    // 删除帖子
    return new Promise((resolve, reject) => {
      delete_api_posts({
        productId: this.productId,
        postId,
      })
        .then(() => this.deleteTargetPost(postId))
        .then(resolve)
        .catch(reject);
    });
  }

  // 在每个列表里删除指定id帖子
  deleteTargetPost(postId) {
    this.setState(preState => ({
      noticeList: omit(preState.noticeList, postId),
      postsList: omit(preState.postsList, postId),
      userList: omit(preState.userList, postId),
    }));
  }

  /**
     * 点赞
     * @param postId        帖子ID
     * @param [replyId]     评论ID，如果存在，则是对评论进行点赞
     * @return {Promise}
     */
  asyncLiked({ postId, replyId }) {
    // 小程序要做额外判断 未登录要弹出登录窗口
    const { productId, state, props } = this;

    if (replyId) {
      // 评论点赞

      const { like_flag, like_count } = state.reply_liked[replyId] || {
        like_flag: false,
        like_count: 0,
      };
      const val = like_flag ? 'off' : 'on';

      this.setState(preState => ({
        reply_liked: {
          ...preState.reply_liked,
          [replyId]: {
            like_flag: !like_flag,
            like_count: PostListSolution.calcLikeCount(like_count, like_flag),
          },
        },
      }));

      return post_api_posts_replies_liked({
        productId,
        postId,
        replyId,
        val,
      });
    }
    // 帖子点赞

    const { like_flag, like_count } = state.post_liked[postId] || {
      like_flag: false,
      like_count: 0,
    };
    const val = like_flag ? 'off' : 'on';
    this.setState(preState => ({
      post_liked: {
        ...preState.post_liked,
        [postId]: {
          like_flag: !like_flag,
          like_count: PostListSolution.calcLikeCount(like_count, like_flag),
        },
      },
    }));

    // 处理i want 点赞。 i want 可能在帖子里也可能在置顶里
    const postKey = state.noticeList[postId] ? 'noticeList' : 'postsList';
    const post = state[postKey][postId];
    this.setState(preState => ({
      [postKey]: {
        ...preState[postKey],
        [postId]: {
          ...post,
          like_list: PostListSolution.calcLikeList(post.like_list, like_flag, props.userData),
        },
      },
    }));

    return post_api_posts_liked({
      productId,
      postId,
      val,
    });
  }

  /**
     * 异步更新评论内容
     * @description 提供给父组件，通过 Ref 来控制子组件更新某个帖子的评论用
     * @param postId
     * @param replyId 新评论的id
     * @return {Promise}
     */
  asyncUpdateReplies(postId, replyId) {
    const { state } = this;

    // 格式化评论字段，帖子列表中的评论和评论接口的字段不一致
    const formatter = item => ({
      ...item,
      f_reply_text: item.content,
      f_reply_id: item.id,
    });

    // 由于发表评论时候的数据结构与现在的评论数据结构不一致，需要单独拉一次接口获取准确的结构
    return get_api_posts_replies({ productId: this.productId, postId })
      .then((resp) => {
        // 只插入新评论而不是全部评论插入
        // const reply = {
        //   [replyId]: formatter(resp.data[replyId]),
        // };

        // 更新帖子中的评论
        ['userList', 'noticeList', 'postsList']
          .filter(key => state[key][postId])
          .forEach((key) => {
            const targetPost = state[key][postId];
            // 这里取返回评论的前三条，而不是将最新的评论加在之前的评论后面
            targetPost.replies = Object.fromEntries(Object.entries(resp.data).slice(0, 3));
            targetPost.reply_count = Object.keys(resp.data).length || Object.keys(targetPost.replies).length;

            this.setState(prevState => ({
              [key]: {
                ...prevState[key],
                [postId]: targetPost,
              },
            }));
          });
      });
  }

  /**
     * 更新特定帖子的状态
     * @param data 最新的帖子状态信息
     * @param opt 操作的action的key
     * @param val 操作action的值
     */
  updatePostAttr({ postData, opt, val }) {
    if (!postData || !postData.id) {
      return;
    }
    const { state } = this;
    const postId = postData.id;
    // 删除操作需要删除所有列表中的该帖子数据，单独处理
    if (opt === 'del') {
      this.deleteTargetPost(postId);
      return;
    }
    // 置顶需要操作两个列表数据，单独处理
    if (opt === 'top') {
      if (val === 'on') {
        const targetPost = state.postsList[postId];
        const newPost = { ...targetPost, is_top: postData.is_top };

        this.setState({
          noticeList: {
            ...state.noticeList,
            [postId]: newPost,
          },
          postsList: omit(state.postsList, postId),
        });
      }

      if (val === 'off') {
        // 除了「默认」标签，其他标签都没有 noticeList
        const targetPost = state.noticeList[postId] || state.postsList[postId];
        const newPost = { ...targetPost, is_top: postData.is_top };

        this.setState({
          noticeList: omit(state.noticeList, postId),
          postsList: {
            ...state.postsList,
            [postId]: newPost,
          },
        });
      }

      return;
    }

    // 其他操作每个列表都可能有，遍历一次
    ['noticeList', 'postsList', 'userList'].forEach((listKey) => {
      if (!state[listKey][postId]) return;

      this.setState((prevState) => {
        const targetList = prevState[listKey];
        const targetPost = targetList[postId];
        const freshPost = {
          ...targetPost,
          is_hidden: postData.is_hidden,
          is_locked: postData.is_locked,
          is_spam: postData.is_spam,
          is_good: postData.is_good,
        };

        return {
          [listKey]: {
            ...targetList,
            [postId]: freshPost,
          },
        };
      });
    });
  }

  /**
     * 异步对帖子、评论做更多操作
     * @param postId
     * @param [replyId] 评论ID，如果存在，则是对评论的操作
     * @param opt
     * @param val
     * @returns {Promise}
     */
  asyncOperation({ postId, replyId, opt, val }) {
    const { productId } = this;

    // 对评论的一些操作
    if (replyId) {
      return new Promise((resolve, reject) => {
        post_api_v2_posts_replies({ productId, postId, replyId, opt, val })
          .then(resolve)
          .catch(reject);
      });
    }

    return new Promise((resolve, reject) => {
      post_api_v2_posts({ productId, postId, opt, val, scene: 'post' })
        .then(resp => this.updatePostAttr({ postData: resp.data, opt, val }))
        .then(resolve)
        .catch(reject);
    });
  }

  /**
     * 异步举报帖子或评论
     * @param postId
     * @param [replyId]
     * @param value
     * @returns {Promise}
     */
  asyncReport({ postId, replyId, value }) {
    const { productId } = this;

    return new Promise((resolve, reject) => {
      post_api_abuse_reports({
        productId,
        object_id: replyId || postId,
        comment: value,
        object_type: replyId ? 'reply' : 'post',
      })
        .then(resolve)
        .catch(reject);
    });
  }

  /**
     * 异步获取帖子的打赏信息
     * @param postIds
     * @return {Promise}
     */
  asyncGetTipBatchInfo(postIds) {
    const { productId } = this;

    return new Promise((resolve, reject) => {
      post_api_tip_batch_info({ productId, postIdList: postIds })
        .then((resp) => {
          this.setState(prevState => ({ tipBatchList: { ...prevState.tipBatchList, ...resp.data } }));
        })
        .then(resolve)
        .catch(reject);
    });
  }

  /**
     * 标签切换导致更新列表
     * @private
     */
  _updateListByLabel() {
    const { state, props, productId, isWilsonBeta } = this;

    /**
         * @type <'威尔逊'|'静态'|'标签'>
         */
    let mode = '';

    if (props.topic) {
      mode = '话题';
    } else {
      if (state.label === LABELS.DEFAULT) {
        if (isWilsonBeta) {
          mode = '威尔逊';
        } else {
          mode = '静态';
        }
      } else {
        mode = '标签';
      }
    }

    const processNetwork = () => {
      const { topicId } = props;

      this.setState({
        isLoading: true,
        pagination: {},
      });

      switch (mode) {
      case '静态':
        return get_api_v2_posts_static({ productId });
      case '威尔逊':
        return get_api_v2_posts_wilson({ productId });
      case '标签':
        return PostListSolution.getListByLabel(state.label)({ productId });
      case '话题':
        return state.label === LABELS.POPULAR
          ? getApiTopicsPostsPopular({ productId, topicId })
          : getProductIDTopicsList({ productId, topicId });
      }
    };
    const tagSetSatate = (respList, topRespList) => new Promise((resolve) => {
      if (isEmpty(respList)) return resolve(respList);
      const { productId } = this;
      const allKeysRespList = !isEmpty(topRespList)
        ? Object.keys(topRespList).concat(Object.keys(respList)) : Object.keys(respList);
      const allValuesRespList = !isEmpty(topRespList)
        ? Object.values(topRespList).concat(Object.values(respList)) : Object.values(respList);
      postListTagsAllGet(productId, 'post', allKeysRespList).then((contentTagList) => {
        if (!isEmpty(contentTagList.data)) {
          getProductsTagsList(productId).then((tagList) => {
            const tagArrList = tagList.data.map(item => ({ ...item, isSet: false }));
            this.setState({ tagArrList });
            const tagsMap = fromEntries(tagList.data.map(tag => [tag.id, tag]));
            for (const item of Object.values(contentTagList.data)) {
              item.tags = Object.values(item.tags).map(id => tagsMap[id]);
            }
            for (const item of allValuesRespList) {
              for (const key of Object.keys(contentTagList.data)) {
                if (item.id === key) item.tags = contentTagList.data[key].tags;
              }
            }
            resolve(respList);
          });
        } else {
          getProductsTagsList(productId).then((tagList) => {
            const tagArrList = tagList.data.map(item => ({ ...item, isSet: false }));
            this.setState({ tagArrList });
            resolve(respList);
          });
        }
      })
        .catch((error) => {
          resolve(respList);
        });
    });

    const processTagUpdateState = async (resData) => {
      const noticeList = resData.data.notice;
      let postsList = resData.data.posts;
      let allList = resData.data;

      if (postsList || !isEmpty(postsList) || noticeList || !isEmpty(noticeList)) {
        postsList = await tagSetSatate(postsList, noticeList);
        return resData;
      } if (allList || !isEmpty(allList)) {
        allList = await tagSetSatate(allList);
        return resData;
      }
      return resData;
    };

    const processUpdateState = (resp) => {
      let newState = {};
      switch (mode) {
      case '静态':
      case '威尔逊':
        if (!resp.data.posts || !resp.data.notice) {
          newState = {
            postsList: [],
            noticeList: {},
            isLoading: false,
          };
          break;
        }
        newState = {
          postsList: resp.data.posts,
          noticeList: resp.data.notice,
          pagination: resp.pagination || {},
          isLoading: false,
        };
        break;
      case '标签':
        newState = {
          postsList: resp.data,
          noticeList: {},
          isLoading: false,
          pagination: resp.pagination,
        };
        break;
      case '话题':
        newState = {
          postsList: resp.data,
          noticeList: {},
          isLoading: false,
          pagination: resp.pagination,
        };
        break;
      }
      this.setState(newState);
    };

    const processAfterEvent = () => {};

    Promise.resolve()
      .then(processNetwork)
      .then(processTagUpdateState)
      .then(processUpdateState)
      .then(processAfterEvent)
      .catch((err) => {
        console.log(err);
      });
  }

  /**
     * 列表变化导致更新点赞数据
     * @param prevState 上一个状态
     * @private
     */
  _updateLikedByList(prevState) {
    const { state, productId } = this;

    const allPosts = { ...state.noticeList, ...state.postsList };
    const postIds = PostListSolution.getDiffIds(prevState, state);
    let replyIds = [];

    try {
      // allPosts[postId] 有可能为 undefined
      replyIds = flatten(postIds.map(postId => Object.keys(allPosts[postId].replies)));
    } catch (error) {
      return;
    }

    // 没有需要查询的数据，不请求
    if (postIds.length === 0) return;

    PostListSolution.asyncGetLiked({
      postIds,
      replyIds,
      productId,
    }).then(([postLiked, replyLiked]) => {
      this.setState({
        post_liked: postLiked || {},
        reply_liked: replyLiked || {},
      });
    });
  }

  // 被父组件ref调用的方法
  loadMore() {
    const { state } = this;

    if (state.isLoading) return;
    this.getLabelPost();
  }

  /**
     * 列表变化导致更新打赏数据
     * @param prevState 上一个状态
     * @private
     */
  _updateBatchByList(prevState) {
    const { state, props, productId } = this;

    // 移动端暂时不需要显示赞赏信息
    if (!props.batch) return;


    const postIds = PostListSolution.getDiffIds(prevState, state);

    // 没有需要查询的数据，不请求
    if (postIds.length === 0) return;

    // 看帖子是否可以打赏
    post_api_tip_batch_list({ productId, postIdList: postIds })
      .then((resp) => {
        this.setState(prevState => ({
          rewardInfo: { ...prevState.rewardInfo, ...resp.data },
        }));
      });

    // 批量获取各贴打赏信息
    this.asyncGetTipBatchInfo(postIds);
  }

  setLabel(label) {
    this.setState({ label, pagination: {} });
  }

  isEmptyList() {
    const { state } = this;

    return Boolean(isEmpty(state.postsList)
            && isEmpty(state.noticeList)
            && !state.pagination.has_more_pages
            && !state.isLoading);
  }

  // 获取标签的帖子数据
  getLabelPost() {
    const { state, props, productId } = this;

    const opt = {
      productId,
      topicId: props.topicId,
    };
    // 威尔逊也是要使用current_page
    if (state.label === LABELS.POPULAR || state.label === LABELS.DEFAULT) {
      opt.page = state.pagination.current_page + 1;
    } else {
      opt.next_page_id = state.pagination.next_page_id;
    }
    PostListSolution.getListByLabel(state.label)(opt)
      .then((res) => {
        const { pagination, data } = res;

        // 威尔逊会有个推荐， 多取一层，NOTE置顶不更新
        const posts = state.label === LABELS.DEFAULT ? data.posts : data;
        this.setState(prevState => ({
          pagination,
          postsList: { ...prevState.postsList, ...toHashObj(posts) },
        }));
      });
  }

  ifEmptyListSetHash(postsList) {
    const { state } = this;

    if (state.label === LABELS.DEFAULT) {
      return true;
    }

    const hasPosts = Boolean(Object.keys(postsList).length);

    if (!hasPosts) {
      this.setState({ label: LABELS.DEFAULT });
    }
  }

  /**
     * 异步获取动态帖子
     * @param {Boolean} is_admin 是否为管理员
     * @param {boolean} [is_latest] 是否获取最新
     * @returns {Promise}
     */
  asyncGetDynamicPosts(is_admin = false, is_latest = true) {
    const { state, productId } = this;

    const opt = {
      productId,
      next_page_id: is_latest ? undefined : state.pagination.next_page_id,
      per_page: is_latest ? undefined : state.pagination.per_page,
    };

    return get_api_v2_posts_user(opt);
  }

  /**
     * 异步获取用户帖子数据
     * 已弃用
     */
  asyncGetUserData() {
    if (this.state.label !== LABELS.DEFAULT) return;

    if (!this.props.userData.user_id && !this.props.isOnPageShow) return;

    Promise.resolve(this.props.userData)

      .then(res => res.is_admin)
      .then(is_admin => this.asyncGetDynamicPosts(is_admin))
      .then(res => this.asyncConnectPosts(res));
  }

  /**
     * 异步合并帖子数据
     * @param res
     * @param {number} [RecursionDepth] 递归次数, 默认最大只递归3次
     * @returns {Promise}
     */
  asyncConnectPosts(res, RecursionDepth = 3) {
    if (!res) {
      return;
    }
    const { state } = this;

    return new Promise((resolve) => {
      let lastPostData;
      let lastDynamicData;
      let lastDynamicTime;
      let lastPostTime;
      let isTimeBoundary;

      try {
        // 获取静态帖子和动态帖子的最后一条数据
        lastDynamicData = last(Object.keys(res.data).map(id => res.data[id]));
        lastPostData = last(Object.keys(state.postsList).map(id => state.postsList[id]));


        // 对比动态贴最后一条的时间是否早于静态晚
        lastDynamicTime = Number(lastDynamicData.created_at_timestamp);
        lastPostTime = Number(lastPostData.created_at_timestamp);
        isTimeBoundary = Boolean(lastDynamicTime >= lastPostTime);
      } catch (error) {
        isTimeBoundary = false;
      }

      this.setState(prevState => ({
        userList: { ...prevState.userList, ...res.data },
      }));

      // 2128 的产品，静态列表存在一个 17 年的数据，导致拉取用户数据会一直拉
      // 需要一个递归上限，避免无休止的拉取用户数据
      if (RecursionDepth === 0) {
        resolve(res);
        return;
      }

      // 如果是普通用户，并且有下一页，当前分页的最后一条帖子的时间比静态帖子晚，就再请求一次动态帖子
      if (!this.props.userData.is_admin && res.pagination.has_more_pages && isTimeBoundary) {
        this.asyncGetDynamicPosts(false, false)
          .then(res => this.asyncConnectPosts(res, RecursionDepth - 1));
      }

      resolve(res);
    });
  }

  /**
   * 所有状态下插入用户新发的帖子
   * @returns
   */
  unshiftUserPost() {
    const { is_admin } = this.props.userData;
    this.asyncGetDynamicPosts(is_admin)
      .then((res) => {
        this.setState(({ postsList }) => ({
          postsList: this.props.topic
            ? { ...PostListSolution.getLatestUserPosts(res.data), ...postsList }
            : { ...postsList, ...PostListSolution.getLatestUserPosts(res.data) },
        }));
      });
  }



  render() {
    const { state, props, productId, isWilsonBeta } = this;

    // 过渡用的兼容代码
    const postState = {
      noticeList: state.noticeList,
      postsList: state.postsList,
      pagination: state.pagination,
      userList: state.userList,
      label: state.label,
    };

    // 过渡用的兼容代码
    const likedState = {
      post: state.post_liked,
      reply: state.reply_liked,
    };

    // 排序
    let {
      noticeList: sortedNoticeList,
      postsList: sortedPostList,
    } = isWilsonBeta && (state.label === LABELS.DEFAULT)
      ? PostListSolution.sortList(postState, 'wilson')
      : PostListSolution.sortList(postState, null, props.topic);

    // 更新点赞的状态
    sortedNoticeList = sortedNoticeList.map(item => PostListSolution.mergeLiked(item, likedState));
    sortedPostList = sortedPostList.map(item => PostListSolution.mergeLiked(item, likedState));

    const renderOptions = {
      noticeList: sortedNoticeList,       //  置顶帖子
      postsList: sortedPostList,          // 普通帖子
      pagination: state.pagination,       // 翻页信息
      rewardInfo: state.rewardInfo,       // 是否允许打赏
      tipBatchList: state.tipBatchList,   // 打赏信息
      isLoading: state.isLoading,          // 加载中
      tagArrList: state.tagArrList,        // 全部标签列表
    };

    if (state.label === LABELS.INIT) {
      return this.props.children(renderOptions);
    }
    return (
      <>
        <PostListLabel
          topic={this.props.topic}
          productId={productId}
          label={state.label}
          onChange={label => this.setLabel(label)}
          userData={this.props.userData}
        />

        {this.props.children(renderOptions)}
      </>
    );
  }
}

PostListSolution.defaultProps = {
  submitPostSuccess: 0,
  rewardInfo: {},
  postsData: {},
  userData: {},
  getPostListInfo: () => console.log('getPostListInfo 没有'),
  getPostDataFromPostList: () => console.log('getPostDataFromPostList 没有'),
};

PostListSolution.propType = {
  /**
     * 产品ID
     */
  productId: PropTypes.number.isRequired,
  /**
     * 当前用户登录信息
     */
  userData: PropTypes.shape({
    user_id: PropTypes.number,
    is_admin: PropTypes.bool,
  }),
  /**
     * 发帖计数，用来获取最新的个人帖子
     */
  submitPostSuccess: PropTypes.number,
  /**
     * 传递 postState 的状态给父组件
     * @param postState
     */
  getPostDataFromPostList: PropTypes.func.isRequired,
  /**
     * 单个帖子的 render props
     * @param noticeList    置顶帖子
     * @param postsList     普通帖子
     * @param pagination    翻页信息
     * @param isEmptyList   是否空列表
     * @param onLoadMore    加载更多的回调
     * @param onClickLike   点赞回调
     */
  children: PropTypes.func.isRequired,
};

