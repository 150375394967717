import { useEffect, useState } from 'react';
import useEffectOnce from 'components/hooks/useEffectOnce';
import { AD_INDEXES, geApiV2BannersByIndexes, ListItem } from 'components/api/wjapi';
import { getApiV1ProductsAttribute } from 'components/api';
import { ATTRIBUTE_STATUS, ATTRIBUTE_TYPE } from 'components/constants/app';

interface UseAdProps {
  productId: string;
  indexes: string;
}

export const useAd = (props: UseAdProps) => {
  const { productId, indexes } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ListItem | null>(null);
  const loadAd = () => {
    geApiV2BannersByIndexes({ indexes })
      .then((res) => {
        if (res.code === 'OK') {
          setData(res.data.list[0]);
        }
        setIsLoading(false);
      });
  };

  useEffectOnce(() => {
    setIsLoading(true);
    getApiV1ProductsAttribute({ productId, type: ATTRIBUTE_TYPE.AD_FEED })
      .then((res) => {
        if (res.data.status === ATTRIBUTE_STATUS.ON) {
          loadAd();
        } else {
          setIsLoading(false);
        }
      });
  });


  return { isLoading, data };
};
