// 后端接口都是下划线链接，这里忽略驼峰校验
/* eslint-disable camelcase */
import { getJson, deleteJson, postJSON, postFormData } from 'components/api/request';
import { ApiRequest, ResponseListBase } from 'components/api/types';


interface GetApiTopicsPostsPopularReq {
  /** 项目ID */
  productId: number | string;
  /** 话题ID */
  topicId: number | string;
  /** 标签 */
  tab?: string;
  /** 当前页面 */
  page?: number;
  /** 每页记录数 */
  per_page?: number;
}

export interface GetApiTopicsPostsPopularData {
  id: string;
  content: string;
  images: any[];
  created_at: string;
  created_at_timestamp: number;
  time: string;
  location: string;
  user_id: number;
  avatar_url: string;
  nick_name: string;
  type: number;
  like_list: any[];
  is_admin: boolean;
  is_notice: boolean;
  is_top: boolean;
  is_locked: boolean;
  is_good: boolean;
  like_count: number;
  reply_count: number;
  replies: any[];
  group_name: string;
  postname: string;
  is_spam: boolean;
  is_todo: boolean;
  is_hidden: boolean;
  is_abuse: boolean;
}
interface GetApiTopicsPostsPopularResp extends ResponseListBase<GetApiTopicsPostsPopularData> {
}

/**
 * 产品-话题-反馈列表-热门获取
 */
export const getApiTopicsPostsPopular: ApiRequest<
  GetApiTopicsPostsPopularReq,
  GetApiTopicsPostsPopularResp
> = ({ productId, topicId, page, tab, per_page }) => getJson({
  url: `/api/v3/${productId}/roadmap/topics/${topicId}/posts/popular`,
  data: {
    page,
    tab,
    per_page,
  },
});
