// 后端接口都是下划线链接，这里忽略驼峰校验
/* eslint-disable camelcase */
import { getJson } from 'components/api/request';
import { horizon, aegis } from 'components/report';
import { ApiRequest, ResponseBase, ProductsReqBase } from 'components/api/types';

export interface GetApiV1UserInfoData {
  is_system: boolean;
  user_id: number;
  is_admin: boolean;
  username?: any;
  nickname: string;
  avatar: string;
  is_spam: boolean;
  is_believable: boolean;
  credit_level: string;
}

/**
 * 获取当前用户信息
 */
export const getApiV1UserInfo: ApiRequest<
  ProductsReqBase,
  ResponseBase<GetApiV1UserInfoData>
> = ({ productId }) => getJson({ url: `/api/v1/${productId}/user/info` }).then((res) => {
  if (res && res.data) {
    // 在获取用户信息后统一设置埋点数据
    horizon.setUserId(res.data.user_id);
    aegis.setUserId(res.data.user_id);
  }
  return res;
});
