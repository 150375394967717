// 后端接口都是下划线链接，这里忽略驼峰校验
/* eslint-disable camelcase */
import { getJson } from 'components/api/request';
import { ApiRequest } from 'components/api/types';
export const AD_INDEXES = {
  /** 首页feed流 */
  HOME: process.env.TARO_ENV === 'weapp' ? 'txc_index_ad_we_mobile' : 'txc_index_ad_mobile',
  /** 首页feed流 -带头像 */
  HOME2: process.env.TARO_ENV === 'weapp' ? 'txc_index_ad2_we_mobile' : 'txc_index_ad2_mobile',
  /** 首屏banner*/
  HOME3: process.env.TARO_ENV === 'weapp' ? 'txc_index_ad3_we_mobile' : 'txc_index_ad3_mobile',
  /** 首屏挂件 */
  HOME4: process.env.TARO_ENV === 'weapp' ? 'txc_index_ad4_we_mobile' : 'txc_index_ad4_mobile',
  /** 常见问题底部 */
  FAQ: process.env.TARO_ENV === 'weapp' ? 'txc_faq_ad_we_mobile' : 'txc_faq_ad_mobile',
};
interface GeApiV2BannersByIndexes {
  indexes: string
}

export interface Root {
  error: Error
  data: Data
  code: string
  request_id: string
  error_type: string
  info: string
  status: number
}

export interface Error {
  params: any
  event: string
  type: string
}

export interface Data {
  list: ListItem[]
}

export interface ListItem {
  id: number
  index: string
  platform: string
  description: string
  image_url: string
  small_image_url: string
  link: string
  title: string
  content: string
}



/** 反馈的评论-根据ID-获取详情 */
export const geApiV2BannersByIndexes: ApiRequest<
  GeApiV2BannersByIndexes, Root
> = ({ indexes }) => getJson({
  url: `/wjapi/v2/banners?indexes=${indexes}`,
  isWjApi: true,
});
