import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Tag from 'components/tag';
import { TcIconChatLine } from 'components/IconSystem';
import './common.less';

export const BtnComment = (props) => {
  const btnClick = () => (!props.is_disabled ? props.onClick() : null);

  return (
    <Tag.div
      className={classNames('btn btn-comment', { disabled: props.is_disabled })}
      role="button"
      tabIndex="0"
      onClick={btnClick}
      onKeyPress={btnClick}
    >
      <TcIconChatLine />
      {props.is_disabled ? (
        <Tag.span className="btn-text">已关闭评论</Tag.span>
      ) : (
        <Tag.span className="btn-text">{props.is_show ? '取消评论' : props.replyNum}</Tag.span>
      )}
    </Tag.div>
  );
};

BtnComment.propTypes = {
  replyNum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  is_disabled: PropTypes.bool,
  is_show: PropTypes.bool,
  onClick: PropTypes.func,
};

BtnComment.defaultProps = {
  replyNum: '评论',
};
