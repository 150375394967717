import { useState, useRef, useEffect } from 'react';
const Notice = [
  '活动由第三方提供',
  '规则请跳转后查看',
];
interface UseNoticeProps {
  trigger: boolean;
}
const useNotice = (props: UseNoticeProps) => {
  const [noticeIndex, setNoticeIndex] = useState(0);
  const timer = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (props.trigger && !timer.current) {
      timer.current = setInterval(() => {
        setNoticeIndex((preNoticeIndex) => {
          if (preNoticeIndex === (Notice.length - 1)) return 0;
          return preNoticeIndex + 1;
        });
      }, 2000);
    }

    return () => {
      clearInterval(timer.current);
    };
  }, [props.trigger]);


  return { notice: Notice[noticeIndex] };
};

export default useNotice;
