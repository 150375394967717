import React from 'react';
import PropTypes from 'prop-types';
import { ariaRole } from 'components/util/aria';
import { getIWantContent, getProductId } from 'components/util';
import { isPlainObject } from 'lodash-es';
import { Avatar } from 'components/user';
import { ImageMobile } from 'components/comment-image';
import {  DescPara } from 'components/post-item/desc-para.mobile';
import { TcIconMore } from 'components/IconSystem';
import Tag from 'components/tag';
import { BtnsStyle } from 'components/btn-icon';
import { CommentItem } from 'components/post-item/comment.mobile';
import { href_post as hrefPost } from 'components/href-helper';
import SupportBtn from 'components/ui/common/support-btn';
import { TAG_COLOR } from 'components/tag-show/constants';
import Topic from 'components/topic/topic-container';
import goodIssue from 'components/assets-image/common-icon/good_issue.png';
import './i-want-card-mobile.less';

class IWantCard extends React.Component {
  getLikeList() {
    const { like_list: likeList } = this.props;
    if (!likeList) {
      return '';
    }
    const str = likeList.join('、');
    return str;
  }

  render() {
    const {
      content: propsContent,
      images,
      replies,
      id,
      tags,
      showTopic,
      is_spam: isSpan,
      is_notice: isNotice,
      is_good: isGood,
      is_hidden: isHidden,
      is_top: isTop,
      is_liked: isLike,
      avatar_url: avatar,
      like_count: likeCount,
      nick_name: nickName,
      reply_count: replyCount,
      time,
      onClickLike,
      onClickUser,
      onClickMore,
      is_admin,
      renderLink,
    } = this.props;
    let commentList = [];
    if (isPlainObject(replies)) {
      commentList = Object.keys(replies).map(id => replies[id]);
    }
    const productId = getProductId();
    const { title, content, solution } = getIWantContent(propsContent);
    return (
      <Tag.div className="i-want-card-mobile">
        <Tag.span
          className="i-want-card-mobile__info"
          onClick={onClickUser}
        >
          <Avatar
            className="i-want-card-mobile__avatar-img item_user"
            src={avatar}
          />
          <SupportBtn isLiked={isLike} count={likeCount} onClick={onClickLike} />
        </Tag.span>
        <Tag.div className="i-want-card-mobile__main">
          <Tag.div className="i-want-card-mobile__detail">
            <Tag.span
              className="i-want-card-mobile__name"
              onClick={onClickUser}>
              {nickName}
            </Tag.span>
            <Tag.div className="i-want-card-mobile__label" aria-hidden={true}>
              {tags && tags.map((item, i) => (
                <Tag.span className="i-want-card-mobile__tag" key={`${i}`}
                  style={{
                    backgroundColor: TAG_COLOR.find(i => i.color === item.setting.color).bg,
                    color: TAG_COLOR.find(i => i.color === item.setting.color).font,
                    border: 'none',
                  }}>{item.name}</Tag.span>
              ))}
              {isSpan && <Tag.span className="i-want-card-mobile__tag">黑名单</Tag.span>}
              {isNotice && <Tag.span className="i-want-card-mobile__tag">公告</Tag.span>}
              {isGood && <Tag.span className="i-want-card-mobile__tag i-want-card-mobile__tag-good"><Tag.img className="label_good__icon" src={goodIssue} alt="好问题" /></Tag.span>}
              {isHidden && <Tag.span className="i-want-card-mobile__tag">隐藏</Tag.span>}
              {isTop && <Tag.span className="i-want-card-mobile__tag">置顶</Tag.span>}
            </Tag.div>
          </Tag.div>
          {showTopic && <Tag.div className="topic_title">
            <Topic productId={productId} postId={id} isProduct />
          </Tag.div>}
          {likeCount >= 1 && (
            <Tag.div className="i-want-card-mobile__like-list">
              <Tag.span isText className="i-want-card-mobile__name-list">{this.getLikeList()}</Tag.span>
              <Tag.span isText>{likeCount > 1 ? `等${likeCount}人` : ''}所见略同</Tag.span>
            </Tag.div>
          )}
          <Tag.a href={hrefPost(id)}>
            <Tag.div className="i-want-card-mobile__title">我想要</Tag.div>
            {/* eslint-disable-next-line camelcase */}
            <Tag.div className="i-want-card-mobile__title-main"><DescPara desc={title} renderLink={renderLink === 'on' || is_admin} /></Tag.div>
            <Tag.div className="i-want-card-mobile__content">
              <Tag.span className="i-want-card-mobile__emoji expression-item" aria-hidden>
                😤{' '}
              </Tag.span>
              <Tag.span className="i-want-card-mobile__title--highlight">
                目前痛点：
              </Tag.span>
              {/* eslint-disable-next-line camelcase */}
              <DescPara desc={content} renderLink={renderLink === 'on' || is_admin} />
            </Tag.div>
            <Tag.div className="i-want-card-mobile__solution">
              <Tag.span className="i-want-card-mobile__emoji expression-item" aria-hidden>
                💡{' '}
              </Tag.span>
              <Tag.span className="i-want-card-mobile__title--highlight">
                我的想法：
              </Tag.span>
              {/* eslint-disable-next-line camelcase */}
              <DescPara desc={solution} renderLink={renderLink === 'on' || is_admin} />
            </Tag.div>
            {
              (Boolean(images.length)) && (
                <Tag.div className="i-want-card-mobile__images">
                  <ImageMobile imgList={images} />
                </Tag.div>
              )
            }
            <Tag.div className="i-want-card-mobile__ctrl">
              <Tag.span className="i-want-card-mobile__time">{time}</Tag.span>
              <Tag.div className="operate_btn">
                <BtnsStyle isMobile={true}>

                  <Tag.button
                    className="btn btn-item"
                    onClick={onClickMore}
                    {...ariaRole('button')}
                    aria-label="更多操作"
                    needAuth
                  >
                    <TcIconMore />
                  </Tag.button>
                </BtnsStyle>
              </Tag.div>
            </Tag.div>
            {
              Boolean(commentList.length) && (
                <Tag.div className="commodule">
                  <Tag.div className="comment_list">
                    {
                      commentList.map((cont, i) => {
                        let parent = {};
                        if (cont.parent_reply_id) {
                          parent = commentList.find(reply => `${reply.id}` === `${cont.parent_reply_id}`);
                        }
                        return (
                          <CommentItem
                            key={i}
                            {...cont}
                            parent={parent}
                          />
                        );
                      })
                    }
                  </Tag.div>
                  {
                  // reply_num 条 这里因为后台数据不能实时更新原因暂时不具体显示还有多少条评论
                    (replyCount > 3) && (
                      <Tag.a className="comment_total" href={hrefPost(id)}>查看全部评论 &gt;</Tag.a>
                    )
                  }

                </Tag.div>

              )
            }
          </Tag.a>
        </Tag.div>
      </Tag.div>
    );
  }
}
IWantCard.propTypes = {
  like_count: PropTypes.number,
  content: PropTypes.string,
  avatar_url: PropTypes.string,
  userName: PropTypes.string,
  id: PropTypes.number,
  tags: PropTypes.array,
  is_spam: PropTypes.bool,
  is_notice: PropTypes.bool,
  is_good: PropTypes.bool,
  is_hidden: PropTypes.bool,
  showTopic: PropTypes.bool,
  is_top: PropTypes.bool,
  images: PropTypes.array,
  replies: PropTypes.array,
  like_list: PropTypes.array,
  reply_count: PropTypes.number,
  nick_name: PropTypes.number,
  solution: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  time: PropTypes.string,
  nickname: PropTypes.string,
  user_id: PropTypes.string,
  created_at_timestamp: PropTypes.string,
  avatar: PropTypes.string,
  is_liked: PropTypes.bool,
  checkLogin: PropTypes.func,
  onClickLike: PropTypes.func,
  onClickUser: PropTypes.func,
  onClickMore: PropTypes.func,
  is_admin: PropTypes.bool,
  renderLink: PropTypes.string,
};

export default IWantCard;
